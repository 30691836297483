<template>
  <div class="container" id="Login">
    <video id="video" autoplay playsInline muted></video>
    <canvas></canvas>
    <b-button @click="tirarFoto">Tirar Foto</b-button>
    <b-button v-if="liberaTroca == true" @click="trocarCamera">Trocar</b-button>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
    <MenuRodape
      @OpenMenuLateral="menuOpen = true"
      :decoded="decoded"
    ></MenuRodape>
  </div>
</template>
<!-- eslint-disable -->
<script>
import locale from "../locale";
import service from "../services";
import jwt from "jsonwebtoken";
import Password from "vue-password-strength-meter";
import Camera from "easy-js-camera";
import MenuRodape from "./MenuRodape";
import MenuLateral from "./MenuLateral";
Camera.isCameraSupported();

export default {
  name: "DashBoard",
  props: {
    msg: String,
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      form: {
        senha: null,
      },
      score: 0,
      erroConexao: "",
      carregando: false,
      logo: "https://bboneapp.s3.amazonaws.com/evo-gerencial-color.png",
      camera: null,
      value: 80,
      max: 100,
      devices: [],
      liberaTroca: true,
    };
  },
  components: { MenuRodape, MenuLateral },
  methods: {
    iniciaCamera(score) {
      var video = document.getElementsByTagName("video")[0];
      var canvas = document.getElementsByTagName("canvas")[0];
      Camera.tryInvokePermission(video, canvas)
        .then((camera) => {
          this.camera = camera;
          camera.start();
          camera.getDevices().then((devices) => {
            this.devices = devices;
            console.log(devices);
          });
        })
        .catch((error) => {
          // Mostly happens if the user blocks the camera or the media devices are not supported
        });
    },
    tirarFoto() {
      this.camera.snapAsBlob().then((foto) => {
        console.log(foto);
      });
    },
    trocarCamera(tryAgain = false) {
      console.log(this.camera);

      this.liberaTroca = false;
      this.camera
        .switch(tryAgain)
        .then((res) => {
          console.log(res);
          this.liberaTroca = true;
        })
        .catch(() => {
          if (tryAgain) return; // This line prevents loops. Because the tryAgain may also fail
          this.camera.switch(true);
        });
    },
  },
  mounted() {
    this.iniciaCamera();
    this.$validator.localize("en", locale);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.carregando {
  color: #fff;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  padding-top: 50%;
}

.logoRodape {
  text-align: center;
  margin-top: 15px;
  font-size: 10px;
  display: block;
}
video {
  width: 100%;
}
</style>
